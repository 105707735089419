import React, {useState} from 'react'
import SelectRow from './SelectRow'
import convertCoords from './convertCoords'

export default function ConvertUi(props) {
    const [coordPositions, setCoordPositions] = useState([])
    console.log(coordPositions)
    const { data } = props
    let dataAsArray = []
    for (let i = 0; i < data.length; i++) {
        dataAsArray.push(Object.values(data[i]))
    }
    console.log(dataAsArray)
    const firstRow = dataAsArray[0]

    function Convert() {
        let convertedData = []
        for (let i = 0; i < dataAsArray.length; i++) {
            //for each row
            let convertedRow = []
            //for each column
            for (let j = 0; j < dataAsArray[i].length; j++) {
                //if the column is in the coordPositions array
                if(coordPositions.includes(j)) {
                    //convert the column
                    convertedRow.push(convertCoords(dataAsArray[i][j]))
                } else {
                    //else, just push the column
                    convertedRow.push(dataAsArray[i][j])
                }
            }
            convertedData.push(convertedRow)
        }
        console.log(convertedData)
        let csvContent = "data:text/csv;charset=utf-8," 
        + convertedData.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
  return (
    <div className='flex justify-center flex-col'>
        {data.lenth && <h1 className='text-white text-2xl'>Select the cells that contain non-decimal coordinates</h1>}
    {
        data.length ? firstRow.map((item, index) => {
            return <SelectRow pos={index} setCoordPositions={setCoordPositions} key={index} item={item}></SelectRow>
        }) : null
    }
    {coordPositions.length ? <button className="text-white bg-blue-400 rounded-lg p-3 mt-3" onClick={Convert}>Convert</button> : null}
    </div>
  )
}
