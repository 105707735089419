import React, { useState} from "react";
import Papa from "papaparse";
import parseData from "./parseData";
import ConvertUi from "./ConvertUi";
 
// Allowed extensions for input file
const allowedExtensions = ["csv"];
 
const App = () => {
    // This state will store the parsed data
    const [data, setData] = useState([]);
     
    // It state will contain the error when
    // correct file extension is not used
    const [error, setError] = useState("");
     
    // It will store the file uploaded by the user
    const [file, setFile] = useState("");
 
    // This function will be called when
    // the file input changes
    const handleFileChange = (e) => {
        setError("");
         
        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
             
            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Please input a csv file");
                return;
            }
 
            // If input type is correct set the state
            setFile(inputFile);
        }
    };
    const handleParse = () => {
         
        // If user clicks the parse button without
        // a file we show a error
        if (!file) return setError("Enter a valid file");
 
        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();
         
        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            setData(parsedData);
            parseData(parsedData)
            console.log(parsedData)
        };
        reader.readAsText(file);
    };
    
 
    return (
        <div className="w-screen h-screen flex flex-col justify-center bg-blue-800">
          <div className="flex justify-center">
            <h1 className="text-white text-4xl text-bold">CSV Coordinate Converter</h1>
          </div>
          <div className="flex justify-center pt-5">

          {!file ? (<div>
            <label htmlFor="csvInput" style={{ display: "block" }} className="bg-blue-500 text-white p-5 rounded-lg">
                  Enter CSV File
              </label>
              <input hidden
                  onChange={handleFileChange}
                  id="csvInput"
                  name="file"
                  type="File"
              />
              </div>) : null}
            {file && !(data.length) ? (<div>
                <button className="bg-blue-500 text-white p-5 rounded-lg ml-3" onClick={handleParse}>Parse</button>
            </div>) : null}
          </div>
          <div className="flex justify-center">
            <div>
                {error ? error : (<ConvertUi data={data} />)}
            </div>
          </div>


        </div>
    );
};
 
export default App;
