export default function convertCoords(coord) {
    //41°38′23″N
    //step one: seperate the degrees, minutes, seconds, and direction
    //step two: convert the degrees, minutes, and seconds to decimal degrees
    //step three: add the direction to the decimal degrees
    //step four: return the decimal degrees
    const nospace = coord.replace(/\s/g, "")
    const a = nospace.split("°")
    const deg = parseInt(a[0])
    const b = a[1].split("′")
    const min = parseInt(b[0])
    const c = b[1].split("″")
    let sec = c[0];
    if(typeof sec !== "number") {
        sec = 0
    } else {
        sec = parseInt(sec)
    }
    
    const direction = nospace.slice(-1);

    const decimal = deg + (min/60) + (sec/3600);
    if (direction === "S" || direction === "W") {
        return decimal * -1;
    }

    return decimal;
    

}