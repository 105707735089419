import React, {useState} from 'react'

export default function SelectRow(props) {
    const {pos, setCoordPositions, item} = props
    const [clicked, setClicked] = useState(false)
    const handleClick = () => {
        setClicked(!clicked)
        if(clicked) {
            setCoordPositions(prev => prev.filter(item => item !== pos))
        } else {
            setCoordPositions(prev => [...prev, pos])
        }
    }

  return (
    <div className='flex justify-left mt-1'>
        <button className="bg-blue-400 text-white rounded-lg px-3 py-1 mr-5" onClick={handleClick}>{clicked ? "Unselect" : "Select"}</button>
        <div className='flex flex-col justify-center text-white'>
            <h1>{!(item === "") ? item : "Empty Text"}</h1>
        </div>
        
    </div>
  )
}
